import CircleCheck from '@cointracker/assets/general/circle-check.svg?react';
import CircleDash from '@cointracker/assets/general/circle-dash.svg?react';
import CircleX from '@cointracker/assets/general/circle-x.svg?react';
import { themeVars } from '@cointracker/ui';
import {
  table,
  tableCell,
  tableHeader,
  tableHeaderCell,
  tableRow,
  tableSection,
} from './ComparisonTable.css';

const SOLANA_COMPARISON_DATA = [
  ['DEX Swaps', 2, 2, 2],
  ['NFTs', 2, 2, 1],
  ['Native staking', 2, 1, 0],
  ['Staking pools / liquid staking', 2, 0, 0],
  ['SPL Staking (Kamino, Meteora, Jupiter Governance, etc.)', 2, 0, 0],
  ['Lending (Kamino, Marginfi, etc.)', 2, 0, 0],
  ['Liquidity Pools(Orca, Kamino, etc.)', 2, 0, 0],
  ['Rewards (Orca, Meteora, Lifinity, etc.)', 2, 0, 0],
  ['Airdrops (JITO, JUP, etc.)', 2, 0, 0],
  ['Rent Fees', 2, 2, 0],
];

const MAP_ICONS = [
  <CircleX key="X" color={themeVars.red.solid} height={24} width={24} />,
  <CircleDash key="dash" color="#FFEB48" height={24} width={24} />,
  <CircleCheck
    key="check"
    color={themeVars.secondary.green}
    height={24}
    width={24}
  />,
];

export const ComparisonTable = () => {
  return (
    <div className={tableSection}>
      <table className={table}>
        <thead>
          <tr className={tableHeader}>
            <th className={tableHeaderCell} />
            <th className={tableHeaderCell}>CoinTracker</th>
            <th className={tableHeaderCell}>Competitor A</th>
            <th className={tableHeaderCell}>Competitor B</th>
          </tr>
        </thead>
        <tbody>
          {SOLANA_COMPARISON_DATA.map((row, index) => (
            <tr className={tableRow} key={'comparison-table-row-' + index}>
              <td className={tableCell}>{row[0]}</td>
              <td className={tableCell}>{MAP_ICONS[row[1] as number]}</td>
              <td className={tableCell}>{MAP_ICONS[row[2] as number]}</td>
              <td className={tableCell}>{MAP_ICONS[row[3] as number]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
