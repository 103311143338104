import { create } from 'zustand';

export interface Partner {
  slug: string;
  niceName: string;
  userId?: string; // Optional unique code for each user that a partner sends to us.
}

interface PartnerStore {
  partner: Partner | null;
  setPartner: (partner: Partner) => void;
}

export const usePartnerStore = create<PartnerStore>((set) => ({
  partner: null,
  setPartner: (partner: Partner) => set({ partner }),
}));
