import { Body, Button, Caption, H2 } from '@cointracker/ui';
import {
  promoHeroIcon,
  promoHeroImage,
  promoLink,
  promotionalContent,
  promotionalInfo,
  promotionalPresentationContainer,
} from '../CoinbasePromotion/CoinbasePromotion.css';
import { container, glow, title } from '../Landing.css';
import CoinbaseOnePromoSVG from './icons/coinbase-one-promo.svg?react';

interface CoinbaseOnePromotionProps {
  toggleModal?: () => void;
}
export const CoinbaseOnePromotion = ({
  toggleModal,
}: CoinbaseOnePromotionProps) => {
  return (
    <section className={container}>
      <H2 className={title}>
        <div className={glow} />
        Coinbase One 🤝 CoinTracker
      </H2>
      <div className={promotionalPresentationContainer}>
        <div className={promoHeroImage}>
          <CoinbaseOnePromoSVG className={promoHeroIcon} />
        </div>
        <div className={promotionalContent}>
          <H2>Free Base plan</H2>
          <div className={promotionalInfo}>
            <Body size="big">
              Coinbase One members get a free Base plan along with free
              performance tracking, tax-loss harvesting, and tax lot views{' '}
              <strong>(up to $410 value)</strong>.{' '}
              <a
                className={promoLink}
                href="https://www.cointracker.io/blog/100-coinbase-coverage"
              >
                Learn more
              </a>
            </Body>
            <Caption size="small" variant="secondary">
              Offer valid until 2025
            </Caption>
            <Button onClick={toggleModal}>Claim your free Base plan</Button>
          </div>
        </div>
      </div>
    </section>
  );
};
