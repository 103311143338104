import { H2 } from '@cointracker/ui';
import { container, glow, title } from '../Landing.css';
import { IntegrationsRow } from './IntegrationsRow';
import { MobileIntegrationsColumn } from './MobileIntegrationsColumn';
import { link, subtext } from './PowerfulIntegrations.css';
import { WalletsRow } from './WalletsRow';

export const PowerfulIntegrations = () => {
  return (
    <section className={container}>
      <H2 className={title}>
        Powerful integrations
        <div className={glow} />
      </H2>
      <span className={subtext}>
        CoinTracker supports 500+ exchanges & wallets, NFTs, 10,000+
        cryptocurrencies, and 20,000+ DeFi smart contracts.{' '}
        <a className={link} href="/integrations">
          See all integrations.
        </a>
      </span>
      <MobileIntegrationsColumn />
      <IntegrationsRow />
      <WalletsRow />
    </section>
  );
};

export default PowerfulIntegrations;
