import { H2 } from '@cointracker/ui';
import {
  container,
  glow,
  presentationContainer,
  subtext,
  title,
} from '../Landing.css';
import { ActivityGrid } from './ActivityGrid';

export const AutomatedDefi = ({ partner }: { partner?: string }) => {
  return (
    <section className={container}>
      <H2 className={title}>
        <div className={glow} />
        Automated DeFi detection
      </H2>
      <div className={presentationContainer}>
        <span className={subtext}>
          CoinTracker automatically syncs all your crypto activity across
          exchanges, wallets, DeFi, and NFTs so you have peace of mind.
        </span>
        <ActivityGrid partner={partner} />
      </div>
    </section>
  );
};
