import HRBlock from '@/components/LandingPartners/icons/hr-block.svg?react';
import OpenSea from '@/components/LandingPartners/icons/open-sea.svg?react';
import TurboTax from '@/components/LandingPartners/icons/turbo-tax.svg?react';
import Uniswap from '@/components/LandingPartners/icons/uniswap.svg?react';
import { combinedLogosContainer, logoIcon } from '../Hero.css';
import BlockchainDotCom from '../icons/blockchain-dot-com.svg?react';
import Brave from '../icons/brave.svg?react';
import CoinbaseLogo from '../icons/coinbase-logo.svg?react';
import CoinbaseOneLogo from '../icons/coinbase-one-logo.svg?react';
import CoinJar from '../icons/coinjar.svg?react';
import Coinsquare from '../icons/coinsquare.svg?react';
import CointrackerBlue from '../icons/cointracker-logo.svg?react';
import EToro from '../icons/etoro.svg?react';
import Metamask from '../icons/metamask.svg?react';
import Phantom from '../icons/phantom.svg?react';
import PlusSign from '../icons/plus-icon.svg?react';
import TrustWallet from '../icons/trust-wallet.svg?react';
import Uphold from '../icons/uphold.svg?react';

export const HeroPartnerLogo = ({ partner }: { partner?: string }) => {
  switch (partner) {
    case 'coinbaseone':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <CoinbaseOneLogo className={logoIcon} />
        </div>
      );
    case 'coinbase':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <CoinbaseLogo className={logoIcon} />
        </div>
      );
    case 'turbotax':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <TurboTax className={logoIcon} />
        </div>
      );
    case 'etoro':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <EToro className={logoIcon} />
        </div>
      );
    case 'brave':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <Brave className={logoIcon} />
        </div>
      );
    case 'metamask':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <Metamask className={logoIcon} />
        </div>
      );
    case 'blockchain.com':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <BlockchainDotCom className={logoIcon} />
        </div>
      );
    case 'phantom':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <Phantom className={logoIcon} />
        </div>
      );
    case 'uphold':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <Uphold className={logoIcon} />
        </div>
      );
    case 'coinsquare':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <Coinsquare className={logoIcon} />
        </div>
      );
    case 'trust-wallet':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <TrustWallet className={logoIcon} />
        </div>
      );
    case 'opensea':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <OpenSea className={logoIcon} />
        </div>
      );
    case 'uniswap':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <Uniswap className={logoIcon} />
        </div>
      );
    case 'hrblock':
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <HRBlock className={logoIcon} />
        </div>
      );
    case 'coinjar': {
      return (
        <div className={combinedLogosContainer}>
          <CointrackerBlue className={logoIcon} />
          <PlusSign className={logoIcon} />
          <CoinJar className={logoIcon} />
        </div>
      );
    }
    default:
      return null;
  }
};
