import { H2 } from '@cointracker/ui';
import {
  container,
  glow,
  presentationContainer,
  subtext as subtextStyle,
  title,
} from '../Landing.css';
import { dataFlowContainer, dataFlowSVG } from './FileProposition.css';
import DarkFlowMapCA from './icons/ct-flow-map-dark-ca.svg?react';
import DarkFlowMapUK from './icons/ct-flow-map-dark-uk.svg?react';
import DarkFlowMap from './icons/ct-flow-map-dark.svg?react';
import DarkFlowMapHRBlock from './icons/ct-hrb-flow-map-dark.svg?react';
import DarkFlowMapTurboTax from './icons/ct-tt-flow-map-dark.svg?react';

interface FilePropositionProps {
  partner?: string;
}
export const FileProposition = ({ partner }: FilePropositionProps) => {
  const { icon, subtext } = getDataFlowMap(partner);
  return (
    <section className={container}>
      <H2 className={title}>
        <div className={glow} />
        File in minutes
      </H2>
      <div className={presentationContainer}>
        <span className={subtextStyle}>{subtext}</span>
        <div className={dataFlowContainer}>{icon}</div>
      </div>
    </section>
  );
};

function getDataFlowMap(partner?: string) {
  switch (partner) {
    case 'turbotax':
      return {
        icon: (
          <DarkFlowMapTurboTax
            className={dataFlowSVG}
            style={{ minWidth: 547 }}
          />
        ),
        subtext: 'File with TurboTax through seamless API import',
      };
    case 'hrblock':
      return {
        icon: (
          <DarkFlowMapHRBlock
            className={dataFlowSVG}
            style={{ minWidth: 547 }}
          />
        ),
        subtext: 'File with H&R Block through seamless API import',
      };
    case 'canada':
      return {
        icon: (
          <DarkFlowMapCA className={dataFlowSVG} style={{ minWidth: 805 }} />
        ),
        subtext:
          'File with TurboTax, H&R Block, or with your own tax professional',
      };
    case 'taxes-uk':
      return {
        icon: (
          <DarkFlowMapUK className={dataFlowSVG} style={{ minWidth: 805 }} />
        ),
        subtext:
          'File with TurboTax, H&R Block, or with your own tax professional',
      };
    default:
      return {
        icon: <DarkFlowMap className={dataFlowSVG} style={{ minWidth: 805 }} />,
        subtext:
          'File with TurboTax, H&R Block, or with your own tax professional',
      };
  }
}
