import { isClient, parseCookie } from '@cointracker/ui';

export const useAuth = () => {
  if (!isClient()) {
    return {
      isAuthenticated: false,
    };
  }
  const cookies = parseCookie(document.cookie);
  const authCookie = cookies?.['is_auth']?.toLowerCase();
  return {
    isAuthenticated: authCookie === 'true',
  };
};
