import {
  OfficialPartnersList,
  Theme,
  isClient,
  useHasMounted,
} from '@cointracker/ui';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import {
  assetContainer,
  bigAssetContainer,
  coinsContainer,
  dashboardContainer,
  dashboardHeroImage,
  heroContainer,
  mobileHeroImage,
  uniswapLogo,
} from '../Hero.css';
import BinanceAsset from '../icons/binance-coin.svg?react';
import BTCAsset from '../icons/bitcoin-coin.svg?react';
import CoinbaseAsset from '../icons/coinbase-coin.svg?react';
import EthereumAsset from '../icons/ethereum-coin.svg?react';
import MaticAsset from '../icons/matic-coin.svg?react';
import RippleAsset from '../icons/ripple-coin.svg?react';
import SolanaAsset from '../icons/solana-coin.svg?react';
import TetherAsset from '../icons/tether-coin.svg?react';
import UniswapAsset from '../icons/uniswap-coin.svg?react';
import USDCAsset from '../icons/usdc-coin.svg?react';

import { usePartnerStore } from '../../partnerStore';
import { getDashboardImage } from './utils';

const IS_CHROMATIC = import.meta.env.PUBLIC_IS_CHROMATIC === 'true';

export const HeroSection = ({ children }: { children?: React.ReactNode }) => {
  const [windowDimensions, setWindowDimensions] = useState({
    width: isClient() ? window.innerWidth : 0,
    height: isClient() ? window.innerHeight : 0,
  });
  const { partner } = usePartnerStore();
  const hasMounted = useHasMounted();
  const dashboardContainerRef = useRef<HTMLDivElement>(null);
  const coinsContainerRef = useRef<HTMLDivElement>(null);

  const theme: Theme = Theme.DARK;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initialPositions = useRef<any>(null);
  const asset1Ref = useRef<HTMLDivElement>(null);
  const asset2Ref = useRef<HTMLDivElement>(null);
  const asset3Ref = useRef<HTMLDivElement>(null);
  const asset4Ref = useRef<HTMLDivElement>(null);
  const asset5Ref = useRef<HTMLDivElement>(null);
  const asset6Ref = useRef<HTMLDivElement>(null);
  const asset7Ref = useRef<HTMLDivElement>(null);
  const asset8Ref = useRef<HTMLDivElement>(null);
  const asset9Ref = useRef<HTMLDivElement>(null);
  const asset10Ref = useRef<HTMLDivElement>(null);

  // running issues here with chromatic flagging changes when there are none.
  const heroImages = getDashboardImage(theme, hasMounted);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const coinContainerLeft = coinsContainerRef.current?.offsetLeft ?? 0;
    initialPositions.current = [
      asset1Ref,
      asset2Ref,
      asset3Ref,
      asset4Ref,
      asset5Ref,
      asset6Ref,
      asset7Ref,
      asset8Ref,
      asset9Ref,
      asset10Ref,
    ].map((ref) => {
      const element = ref.current;
      return element
        ? {
            top: element.offsetTop,
            left: element.offsetLeft + coinContainerLeft,
          }
        : null;
    });
  }, [windowDimensions]);

  useEffect(() => {
    let animationFrameId: number;

    const handleScroll = () => {
      const dashboardContainer = dashboardContainerRef.current;
      const assetContainers = [
        asset1Ref,
        asset2Ref,
        asset3Ref,
        asset4Ref,
        asset5Ref,
        asset6Ref,
        asset7Ref,
        asset8Ref,
        asset9Ref,
        asset10Ref,
      ].map((ref, index) => ({
        element: ref.current,
        initialPosition: initialPositions.current[index],
      }));

      if (dashboardContainer && !IS_CHROMATIC) {
        const dashboardRect = dashboardContainer.getBoundingClientRect();
        const scrollY = window.scrollY;
        const targetTop = dashboardRect.top + dashboardRect.height + 120; // target bottom 20% of the dashboard image.
        const targetLeft = window.innerWidth / 2 - 45; // just use center of the screen. -- offset by 45 for coinsize to ensure actually midpoint of screen.
        const updates = assetContainers.map(({ element, initialPosition }) => {
          if (element && initialPosition) {
            // speed modifier is used to adjust the speed of the coins. higher than 1 is slower.
            const speedModifier = 1.35;
            const adjustedScrollY = Math.max(scrollY - 20, 0); // wait 20px before starting animation. (looks better)
            const progress = Math.min(
              Math.max(
                adjustedScrollY /
                  (speedModifier *
                    (targetTop -
                      dashboardRect.top -
                      dashboardRect.height * 0.5)),
                0,
              ),
              1,
            );
            let translateX, translateY, rotation, opacity;

            if (progress >= 0.6 && progress < 0.95) {
              opacity = `${1 - (progress - 0.6) / 0.35}`;
            } else if (progress >= 0.95) {
              opacity = '0';
            } else {
              opacity = '1';
            }

            if (
              progress < 1 ||
              (progress === 1 &&
                dashboardRect.top < -dashboardRect.height * 0.9)
            ) {
              const newTop =
                initialPosition.top +
                (targetTop - initialPosition.top) * progress;
              const newLeft =
                initialPosition.left +
                (targetLeft - initialPosition.left) * progress;
              translateX = newLeft - initialPosition.left;
              translateY = newTop - initialPosition.top;
              rotation = progress * 2 * 360 + 0.1;
            }
            return { element, translateX, translateY, rotation, opacity };
          }
          return null;
        });

        animationFrameId = requestAnimationFrame(() => {
          updates.forEach((update) => {
            if (update) {
              update.element.style.transform = `translate3d(${update.translateX}px, ${update.translateY}px, 0) rotate3d(0,0,1,${update.rotation}deg)`;
              update.element.style.opacity = update.opacity;
            }
          });
        });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (animationFrameId !== undefined) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [windowDimensions]);

  return (
    <section className={heroContainer}>
      <div className={coinsContainer} ref={coinsContainerRef}>
        <div
          className={classNames(assetContainer, bigAssetContainer)}
          style={{ top: '0%', left: '15%' }}
          ref={asset1Ref}
        >
          <EthereumAsset />
        </div>
        <div
          className={classNames(
            // theme === Theme.LIGHT ? solanaLightThemeLogo : '',
            assetContainer,
          )}
          ref={asset9Ref}
          style={{
            top: '23%',
            left: '0',
          }}
        >
          <SolanaAsset />
        </div>
        <div
          className={assetContainer}
          ref={asset2Ref}
          style={{
            top: '45%',
            left: '14%',
          }}
        >
          <CoinbaseAsset />
        </div>
        <div
          className={assetContainer}
          ref={asset3Ref}
          style={{
            top: '70%',
            left: '0%',
          }}
        >
          <TetherAsset />
        </div>
        <div
          className={assetContainer}
          ref={asset4Ref}
          style={{
            bottom: '0%',
            left: '16%',
          }}
        >
          <USDCAsset />
        </div>
        <div
          className={assetContainer}
          ref={asset5Ref}
          style={{
            top: '0%',
            right: '15%',
          }}
        >
          <BTCAsset />
        </div>
        <div
          className={classNames(assetContainer, bigAssetContainer)}
          ref={asset10Ref}
          style={{
            top: '21%',
            right: '0%',
          }}
        >
          <MaticAsset />
        </div>
        <div
          className={assetContainer}
          ref={asset6Ref}
          style={{
            top: '45%',
            right: '14%',
          }}
        >
          <RippleAsset />
        </div>
        <div
          className={uniswapLogo}
          ref={asset7Ref}
          style={{
            top: '71%',
            right: '0%',
          }}
        >
          <UniswapAsset />
        </div>
        <div
          className={assetContainer}
          ref={asset8Ref}
          style={{
            bottom: '0%',
            right: '16%',
          }}
        >
          <BinanceAsset />
        </div>
      </div>
      {children}

      <OfficialPartnersList partner={partner?.slug} />

      <div className={dashboardContainer} ref={dashboardContainerRef}>
        <img
          className={dashboardHeroImage}
          src={heroImages?.desktop}
          alt="dashboard"
        />
        <img
          className={mobileHeroImage}
          src={heroImages?.mobile}
          alt="dashboard"
        />
      </div>
    </section>
  );
};
