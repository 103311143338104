import { H2 } from '@cointracker/ui';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { container, glow, title } from '../Landing.css';
import {
  card,
  cardIcon,
  cardIconContainer,
  cardRow,
  cardText,
  desktopHeight,
  mobileHeight,
} from './SecurityProposition.css';
import ReadOnlyIcon from './icons/folder.svg?react';
import Lock from './icons/lock.svg?react';
import Shield from './icons/shield.svg?react';

export const SecurityProposition = () => {
  return (
    <section className={container}>
      <H2 className={title}>
        <div className={glow} />
        Bulletproof security
      </H2>
      <div className={cardRow}>
        <article className={card}>
          <div
            className={cardIconContainer}
            style={assignInlineVars({
              [desktopHeight]: '75px',
              [mobileHeight]: '52px',
            })}
          >
            <ReadOnlyIcon className={cardIcon} />
          </div>
          <span className={cardText}>Read-only access to your data</span>
        </article>
        <article className={card}>
          <div
            className={cardIconContainer}
            style={assignInlineVars({
              [desktopHeight]: '100px',
              [mobileHeight]: '70px',
            })}
          >
            <Shield className={cardIcon} />
          </div>
          <span className={cardText}>
            End-to-end encryption and token-based 2FA
          </span>
        </article>
        <article className={card}>
          <div
            className={cardIconContainer}
            style={assignInlineVars({
              [desktopHeight]: '88px',
              [mobileHeight]: '62px',
            })}
          >
            <Lock className={cardIcon} />
          </div>
          <span className={cardText}>Certified SOC 1 and SOC 2 compliant</span>
        </article>
      </div>
    </section>
  );
};
