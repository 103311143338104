import { H1 } from '@cointracker/ui';
import { glow, title } from '../Landing.css';
import { ComparisonTable } from './ComparisonTable';
import { tableSectionContainer } from './ComparisonTable/ComparisonTable.css';

export const BuiltForUsers = () => {
  return (
    <section className={tableSectionContainer}>
      <H1 className={title}>
        <div className={glow} />
        Solana DeFi classification accuracy
      </H1>
      <ComparisonTable />
    </section>
  );
};
