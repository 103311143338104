import { H3 } from '@cointracker/ui';
import classNames from 'classnames';
import {
  container,
  industryRow,
  industryRowDiv,
  industryRowIcon,
  industryTopRow,
  industryTopRowDiv,
  industryTopRowIcon,
  logoContainerIcon,
  title,
} from './IndustryProposition.css';
import Initialized from './icons/Initialized.svg?react';
import Accel from './icons/accel.svg?react';
import CoinbaseVentures from './icons/coinbase-ventures.svg?react';
// both of these are not my favorite SVG since they are just images inside of an SVG
// we should upgrade these in the future when we get real vectors from partners.
import IntuitVentures from './icons/intuit-ventures.svg?url';
import KrakenVentures from './icons/kraken-ventures.svg?react';
import YC from './icons/y-combinator.svg?react';

export const IndustryProposition = () => {
  return (
    <section className={container}>
      <H3 className={title}>Backed by $100M from the best in the industry</H3>
      <div className={industryTopRow}>
        <div className={industryTopRowDiv}>
          <CoinbaseVentures
            className={classNames(industryTopRowIcon, logoContainerIcon)}
          />
        </div>
        <div className={industryTopRowDiv}>
          <KrakenVentures
            className={classNames(industryTopRowIcon, logoContainerIcon)}
          />
        </div>
      </div>
      <div className={industryRow}>
        <div className={industryRowDiv}>
          <img
            src={IntuitVentures}
            className={classNames(industryRowIcon, logoContainerIcon)}
            alt="intuit ventures"
          />
        </div>
        <div className={industryRowDiv}>
          <YC className={classNames(industryRowIcon, logoContainerIcon)} />
        </div>
        <div className={industryRowDiv}>
          <Accel className={classNames(industryRowIcon, logoContainerIcon)} />
        </div>
        <div className={industryRowDiv}>
          <Initialized
            className={classNames(industryRowIcon, logoContainerIcon)}
          />
        </div>
      </div>
    </section>
  );
};
