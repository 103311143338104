import { useAuth } from '@/hooks/useAuth';
import {
  URLS,
  darkThemeClass,
  isClient,
  useSearchParams,
} from '@cointracker/ui';
import classNames from 'classnames';
import { useEffect, useState, type ReactNode } from 'react';
import { CBO_PROMO_KEY } from '../CoinbaseOnePromotionPage/CoinbaseOnePromotionPage/constants';
import { AutomatedDefi } from './AutomatedDefi';
import { BuiltForUsers } from './BuiltForUsers';
import { CoinbaseOneModal } from './CoinbaseOneModal';
import { CoinbaseOnePromotion } from './CoinbaseOnePromotion';
import { CoinbasePromotion } from './CoinbasePromotion';
import { FileProposition } from './FileProposition';
import { FooterPitch } from './FooterPitch';
import { Hero } from './Hero';
import { IndustryProposition } from './IndustryProposition';
import { pageContainer } from './Landing.css';
import { PowerfulIntegrations } from './PowerfulIntegrations';
import { SecurityProposition } from './SecurityProposition';
import { Testimonials } from './Testimonials';
import { YearRoundValue } from './YearRoundValue';
import { usePartnerStore } from './partnerStore';
import {
  determineDiscountText2024,
  getPartner,
  processPartnerSpecificURLParams,
  setCookies,
} from './utils';

interface LandingProps {
  partnerSlug?: string;
  children?: ReactNode;
}
export const Landing = ({ partnerSlug, children }: LandingProps) => {
  const [searchParams] = useSearchParams();
  const { setPartner } = usePartnerStore();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (partnerSlug) {
      const partner = getPartner(partnerSlug);
      if (!partner) return;

      processPartnerSpecificURLParams(partnerSlug, searchParams);
      partner.userId = searchParams.get('partner_id') ?? undefined;
      setPartner(partner);
      setCookies(partner, searchParams.get('utm_source') ?? undefined);
      if (isAuthenticated) {
        // redirect to dashboard
        window.location.href = `${URLS.HOME_PAGE}${window.location.search}`;
      }
    }
  }, [searchParams, setPartner, partnerSlug, isAuthenticated]);

  const isCoinbaseOne = partnerSlug === 'coinbaseone';
  const hasPromoKeyInStorage =
    isClient() && Boolean(localStorage.getItem(CBO_PROMO_KEY));
  const hasPromoKeyInURL = searchParams.has('c');
  const shouldAutoPop =
    isCoinbaseOne && (hasPromoKeyInStorage || hasPromoKeyInURL);
  const [isOpen, setIsOpen] = useState(shouldAutoPop);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const heroProps = getHeroProps(partnerSlug, toggleModal);
  return (
    <main className={classNames(darkThemeClass, pageContainer)}>
      <Hero {...heroProps} />
      <CoinbaseOneModal toggleModal={toggleModal} isOpen={isOpen} />
      {heroProps.additionalSection?.(toggleModal)}
      <Testimonials partner={partnerSlug} />
      <PowerfulIntegrations />
      <AutomatedDefi partner={partnerSlug} />
      <FileProposition partner={partnerSlug} />
      <SecurityProposition />
      <YearRoundValue />
      <IndustryProposition />
      {children}
      <FooterPitch {...heroProps} />
    </main>
  );
};

export type getHeroProps = {
  title?: string;
  subtitle?: string;
  discountText?: string;
  additionalSection?: (toggleModel?: () => void) => React.ReactNode | null;
  registerButtonTitle?: string;
  toggleModal?: () => void;
};

const getHeroProps = (
  partner?: string,
  toggleModal?: () => void,
): getHeroProps => {
  switch (partner) {
    case 'coinbaseone':
      return {
        subtitle:
          'Connect your Coinbase account, exchanges, and DeFi wallets to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'Coinbase One members get a free Base plan.',
        ),
        additionalSection: (toggleModal) => (
          <CoinbaseOnePromotion toggleModal={toggleModal} />
        ),
        registerButtonTitle: 'Claim your free Base plan',
        toggleModal: toggleModal,
      };
    case 'coinbase':
      return {
        subtitle:
          'Connect Coinbase or Coinbase Wallet, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users get 20% off all plans.',
        ),
        additionalSection: () => <CoinbasePromotion />,
      };
    case 'turbotax':
      return {
        subtitle:
          'Connect your crypto wallets and exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'New users from TurboTax get 15% off until 2025.',
        ),
      };
    case 'etoro':
      return {
        subtitle:
          'Connect eToro, plus your other wallets or exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from eToro get 10% off until 2025.',
        ),
      };
    case 'phantom':
      return {
        subtitle:
          'Connect Phantom, plus your other wallets or exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from Phantom get 20% off until 2025.',
        ),
      };
    case 'brave':
      return {
        subtitle:
          'Connect Brave, plus your other wallets or exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(),
      };
    case 'blockchain.com':
      return {
        subtitle:
          'Connect Blockchain.com, plus your other wallets or exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from Blockchain.com get 10% off until 2025.',
        ),
      };
    case 'uphold':
      return {
        subtitle:
          'Connect Uphold, plus your other wallets or exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from Uphold get 10% off until 2025.',
        ),
      };
    case 'opensea':
      return {
        subtitle:
          'Connect your crypto wallets and exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'New users from OpenSea get 15% off until 2025.',
        ),
      };
    case 'trust-wallet':
      return {
        subtitle:
          'Connect Trust Wallet, plus your other wallets or exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from Trust Wallet get 20% off until 2025.',
        ),
      };
    case 'uniswap':
      return {
        subtitle:
          'Connect your crypto wallets and exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'New users from Uniswap get 20% off until 2025.',
        ),
      };
    case 'hrblock':
      return {
        subtitle:
          'Connect your crypto wallets and exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'New users from H&R Block get 20% off until 2025.',
        ),
      };
    case 'coinsquare':
      return {
        subtitle:
          'Connect your crypto wallets and exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'New users from Coinsquare get 10% off until 2025.',
        ),
      };
    case 'metamask': {
      return {
        subtitle:
          'Connect MetaMask, your exchanges, and DeFi wallets to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from MetaMask get 10% off until 2025.',
        ),
      };
    }
    case 'solana': {
      return {
        subtitle:
          'Connect your crypto wallets and exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(
          'New users from Solana get 20% off until 2025.',
        ),
        additionalSection: () => <BuiltForUsers />,
      };
    }
    case 'canada': {
      return {
        title: 'Crypto & NFT Taxes for Canada',
        subtitle:
          'Easily sync wallets and generate CRA tax forms. Trusted by 1 million+ users',
      };
    }
    case 'taxes-uk': {
      return {
        title: 'Crypto & NFT Taxes for the UK',
        subtitle:
          'Easily sync wallets and prepare HRMC forms. Trusted by 1 million+ users',
      };
    }
    case 'coinjar': {
      return {
        subtitle:
          'Connect CoinJar, plus your other wallets and exchanges, to get your crypto tax reports in minutes.',
        discountText: determineDiscountText2024(
          'New users from CoinJar get 20% off through June 30, 2024.',
          '2024-07-01T00:00-08:00',
        ),
      };
    }
    default: {
      return {
        subtitle:
          'Connect your crypto wallets and  exchanges to get your optimized tax report in minutes.',
        discountText: determineDiscountText2024(),
      };
    }
  }
};
